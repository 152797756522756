import React, { useState } from 'react';
import Swal from 'sweetalert2';
import './home.css';
import { Link } from 'react-scroll';
import { Button, Row, Col, Carousel, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import profile3 from './images/profile3.svg';
import about from './images/about.svg';
import experience from './images/experience.svg'
import aws from './images/aws.svg'
import html from './images/html.svg'
import css from './images/css.svg'
import bootstrap from './images/bootstrap.svg'
import js from './images/js.svg'
import react from './images/react.svg'
import sc1 from './images/sc1.png'
import sc3 from './images/sc3.png'
import sc4 from './images/sc4.png'
import sc5 from './images/sc5.png'
import sc6 from './images/sc6.png'
import sc7 from './images/sc7.png'
import sc8 from './images/sc8.png'
import sc9 from './images/sc9.png'
import sc10 from './images/sc10.png'
import scr1 from './images/scr1.png'
import scr2 from './images/scr2.png'
import scr3 from './images/scr3.png'
import scr4 from './images/scr4.png'
import scr5 from './images/scr5.jpeg'
import scr6 from './images/scr6.jpeg'
import scr7 from './images/scr7.jpeg'
import scr8 from './images/scr8.jpeg'
import scr9 from './images/scr9.jpeg'
import scr10 from './images/scr10.jpeg'



function Home() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  }
  // State to manage the active tab
  const [activeTab, setActiveTab] = useState('skills');

  // Handler to switch tabs
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const AnimationComponent = () => {
    const [animate, setAnimate] = useState(false);

    const handleRefresh = () => {
      setAnimate(false); // Reset animation
      setTimeout(() => {
        setAnimate(true); // Trigger animation
      }, 100); // Short delay to ensure reset
    };
  }
  let sucess = () => {
    Swal.fire({
      title: "Successfully Submited",
      text: "thank you for vist our page ",
      icon: "success"
    });
  }
  return (
    <>


      <nav className='x' >
        <div className="container">
          <p className="logo"><Link to="home" smooth={true} duration={500}>Portfolio</Link></p>
          <div className="mobile-menu-icon" onClick={() => setIsOpen(!isOpen)}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
          <div className={`nav-links ${isOpen ? 'active' : ''}`}>
            <ul>
              <li><Link to="home" smooth={true} offset={(-250)} duration={100}>Home</Link></li>
              <li><Link to="about" smooth={true} offset={(-58)} duration={100}>About</Link></li>
              <li><Link to="experience" smooth={true} offset={(-60)} duration={100}>Experience</Link></li>
              <li><Link to="projects" smooth={true} offset={(-60)} duration={100}>Projects</Link></li>
              <li><Link to="contact" smooth={true} offset={(-60)} duration={100}>Contact</Link></li>

            </ul>
          </div>
        </div>
      </nav>



      <div id="home" className='z'>       
        

            <div className="intro-content">
              <p className='para'>Hi There,</p>
              <p className='para1'>I'm <span className='span1'>Leander Xavier A</span></p>
              <p className='para2'>Front-End Web Developer</p>
              <p className='para3'>
                As an aspiring entry-level developer excited to begin my journey in the field, I’m actively seeking job opportunities where I can apply my skills and grow as a developer. This portfolio is a reflection of my dedication to build visually appealing and user-friendly web applications.
              </p>
              <a href="/cv.pdf" download="resume.pdf">  <button className='button1'><span class="button1-content">Download CV</span></button></a>
            </div>
          
        
            <img className='img1' src={profile3} alt="Profile" />
          
        

      </div>

      {/* About Me Section */}
    
      <div id="about" className='y'>
        
            <br/>
          
            <p className='about'>About Me</p>
            <p className='para4'>
              Hello! I'm A.Leander Xavier from Vriddhachalam,Cuddalore. My fascination with web
               development started with
              a curiosity about how websites are built and operated behind the scenes.
              This initial curiosity quickly grew into a passion,
              leading me to pursue web development professionally. During my internship,
              I worked on various projects, from creating responsive layouts to implementing
              interactive features. This hands-on experience has significantly enhanced
              my ability to develop effective and engaging web solutions.
            </p>


            <div className='tab-titles'>
              <p
                className={`tab-links ${activeTab === 'skills' ? 'active-link' : ''}`}
                onClick={() => handleTabClick('skills')}
              >
                Skills
              </p>
              <p
                className={`tab-links ${activeTab === 'Education' ? 'active-link' : ''}`}
                onClick={() => handleTabClick('Education')}
              >
                Education
              </p>
            </div>
            <div className={`tab-contents ${activeTab === 'skills' ? 'active-tab' : ''}`} id="skills">
              <ul>
                <li><span>HTML/CSS</span><br />Structure and Style Web Pages</li>
                <li><span>BOOTSTRAP</span><br />Responsive Design Framework</li>
                <li><span>JAVASCRIPT</span><br />Dynamic Web Functionality</li>
                <li><span>REACT JS</span><br />Component-Based Library</li>

              </ul>
            </div>
            <div className={`tab-contents ${activeTab === 'Education' ? 'active-tab' : ''}`} id="Education">
              <ul>
                <li><span>M.Sc. Computer Science - (2023)</span><br />Bishop Heber College</li>
                <li><span>B.Sc. Computer Science - (2021)</span><br />Bishop Heber College</li>
                <li><span>HSC - (2018)</span><br />Sowdambika Matriculation Higher Secondary School</li>
                <li><span>SSLC - (2015)</span><br />Fatima Matriculation Higher Secondary School</li>
              </ul>
            </div>   
            <br/>
            <img className='img2' src={about} alt="About Me" />
      </div>
      
      {/* experience */}
      <div id="experience" className='w'>
        
          
            <p className='exper'>Experience</p>
            <p className='para5'>Internship at Glacier Technology</p>
            <p className='para6'>Responsibilities</p>
            <div className='para7'>
              <ul>
                <li> Developed responsive web pages for all devices..</li>
                <li> Utilized React.js to create interactive UI components.</li>
                <li> Cloned Facebook and Netflix webpages, ensuring responsiveness.</li>
                <li> Created mini-projects: portfolio, e-commerce, calculators, to-do list.</li>
              </ul>
            </div>          
         
            <img className='img3' src={experience} />
          
          <p className='para8'>Technologies Used</p>
          <div className="carousel-container">
          

            <div className="carousel2" style={{
              '--width': '110px',
              '--height': '50px',
              '--quantity': '10',
              '--duration': '15s'
            }}>
              <div className="carousel__list">
                <img className="carousel__item " src={aws} style={{ '--position': '1' }}></img>
                <img className="carousel__item " src={html} style={{ '--position': '2' }}></img>
                <img className="carousel__item" src={css} style={{ '--position': '3' }}></img>
                <img className="carousel__item " src={bootstrap} style={{ '--position': '4' }}></img>
                <img className="carousel__item " src={js} style={{ '--position': '5' }}></img>
                <img className="carousel__item " src={react} style={{ '--position': '6' }}></img>
                <img className="carousel__item " src={aws} style={{ '--position': '7' }}></img>
                <img className="carousel__item " src={html} style={{ '--position': '8' }}></img>
                <img className="carousel__item" src={css} style={{ '--position': '9' }}></img>
                <img className="carousel__item " src={bootstrap} style={{ '--position': '10' }}></img>
           
              </div>
              
            </div>

          </div>
       

      </div>
      
      {/* Project */}

      <div id="projects" className='projects' >
        <p className='project'>Project</p>
        <p className='para9'>F&F - Fill your tank & Fix your vehicle</p>
        <p className='para10'>Our platform is designed to provide seamless fuel
          delivery and connect you with nearby <br /> mechanical shops,making your
          life easier and your vehicle's upkeep hassle-free.</p>
        <div className='slider'>
          <div className='slide-track'>
            <div className='slide'>
              <img src={sc1} />
            </div>
            <div className='slide'>
              <img src={sc3} />
            </div>
            <div className='slide'>
              <img src={sc4} />
            </div>
            <div className='slide'>
              <img src={sc5} />
            </div>
            <div className='slide'>
              <img src={sc6} />
            </div>
            <div className='slide'>
              <img src={sc7} />
            </div>
            <div className='slide'>
              <img src={sc8} />
            </div>
            <div className='slide'>
              <img src={sc9} />
            </div>
            <div className='slide'>
              <img src={sc10} />
            </div>
            <div className='slide'>
              <img src={sc1} />
            </div>
            <div className='slide'>
              <img src={sc3} />
            </div>
            <div className='slide'>
              <img src={sc4} />
            </div>
            <div className='slide'>
              <img src={sc5} />
            </div>
            <div className='slide'>
              <img src={sc6} />
            </div>
            <div className='slide'>
              <img src={sc7} />
            </div>
            <div className='slide'>
              <img src={sc8} />
            </div>
            <div className='slide'>
              <img src={sc9} />
            </div>
            <div className='slide'>
              <img src={sc10} />
            </div>
          </div>
        </div>
        <div>
          <p className='para11'>Mini Projects</p>
          <p className='para12'>Practiced Website clowns like Facebook Login/Signup, Netflix
            Webpage with mobile responsive.
            Created individual mini project Portfolio, E-commerce website,
            Calculators and Todo list</p>
        </div> 

        <div className='slider1'>
          <div className='slide1-track1'>
            <div className='slide1'>
              <img src={scr1} />
            </div>
            <div className='slide1'>
              <img src={scr2} />
            </div>
            <div className='slide1'>
              <img src={scr3} />
            </div>
            <div className='slide1'>
              <img src={scr4} />
            </div>
            <div className='slide1'>
              <img src={scr5} />
            </div>
            <div className='slide1'>
              <img src={scr6} />
            </div>
            <div className='slide1'>
              <img src={scr7} />
            </div>
            <div className='slide1'>
              <img src={scr8} />
            </div>
            <div className='slide1'>
              <img src={scr9} />
            </div>
            <div className='slide1'>
              <img src={scr10} />              
            </div>
            <div className='slide1'>
              <img src={scr1} />
            </div>
            <div className='slide1'>
              <img src={scr2} />
            </div>                     
            </div>
            </div>
      </div>

      {/*------contact----- */}

      <div className='bgcontact'>
       
            <p className='contact' >Contact Me</p>
           <div className='form'>
              <Form.Control type="text" placeholder="Full Name" /><br />
              <Form.Control type="email" placeholder="Email" /><br />
              <Form.Control type="number" placeholder="Mobile Number" /><br />
              <Form.Control type="text" placeholder="Subject" /><br />
              <Form.Control as="textarea" placeholder="Your Message" rows={8} /><br />
              </div>
              <button className='button2' onClick={sucess}><span class="button2-content">Submit</span></button>
           
          <br/>
      </div>
     
      <div className='end' >       
            <div className='font'>
              <a href="https://www.linkedin.com/in/leander-xavier-71ba30254?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank"><i class="bi bi-linkedin"></i></a>
              <a href="mailto:leanderxavier71@gmail.com"
                target="_blank"> <i class="bi bi-envelope-at-fill"></i></a>
              <i class="bi bi-instagram"></i>
              <i class="bi bi-facebook"></i>
              <i class="bi bi-twitter-x"></i>
              <i class="bi bi-github"></i>
              <a href='tel:+919976610821'> <i class="bi bi-telephone-fill"></i></a>
            </div>
            <div className='footer'>
              <ul className='ul1'>
                <li className='li1' >FAQ</li>
                <li className='li1'>Project</li>
                <li className='li1'>About</li>
                <li className='li1'>Contact</li>
                <li className='li1'>Privacy Policy</li>
              </ul>
              <p className='copyrights'>Website Created @Leanderxavier</p>
            </div>         
      </div>



    </>
  );
}

export default Home;
